@import 'styles/extends/_generics';

.tr__document-table {
    .tr__collapse {
        overflow: initial;
        .tr__collapse--content {
            padding: 0 10px;
        }

        &.active {
            .tr__collapse--content {
                padding: 10px;
            }
        }
    }
    .tr__document-table--header{
        color: $label-gray;
        font-weight: 500;
    }
    .table-row {
        .column {
            flex: 0 0 20%;
            color: $base-text-color;

            &:nth-child(1),
            &:nth-child(2) {
                flex: 0 0 30%;
            }
        }
    }
    
    &--content {
        border-top: 1px solid $button-hover-gray;
        .column {
            padding: 15px;

            .tr__dropdown,
            .tr__field {
                flex-grow: 1;
            }
            
            > i {
                @extend %close-icon;
            }

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
            }
        }
    }

    &--footer {
        padding: 20px 10px 10px 10px;
    }
}