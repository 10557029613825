@import 'styles/extends/_generics';

.tr__organization-members-avatar {
    margin-left: 7px;
    &.hoverable {
        cursor: pointer;
        @extend %enable-transition;

        &:hover {
            @include opacity(0.5);
        }
    }

    &--item {
        border-radius: $organization-avatar-size;
        width: $organization-avatar-size;
        height: $organization-avatar-size;
        overflow: hidden;
        border: 2px solid $white;
        background-color: $white;
        margin-left: -7px;
        background-color: $bottom-border-gray;
        @include flex(row, center, center);

        img {
            width: 100%;
        }

        span {
            font-size: $tiny-size;
        }
    }

    &.small {
        .tr__organization-members-avatar {
            &--item {
                border-width: 1px;
                width: $organization-avatar-small-size;
                height: $organization-avatar-small-size;
                border-radius: $organization-avatar-small-size;
            }
        }
    }
}