.tr__shipment-progresses {
    .tr__active-shipment-step {
        flex-grow: 1;
        min-width: calc(100% / 9);

        &:last-child {
            padding-right: 0;
            &:before {
                display: none;
            }
        }
    }
}