@import 'styles/extends/_generics';

$button-size: 200px;

.tr__verify-details-form {
    &--group {
        &__input {
            padding: 0 15px;
            .title {
                p {
                    font-size: $small-size;
                }
            }
        }
        &--content {
            &-frame {
                padding: 0 20px;
            }
            &:last-child {
                border: none !important;
            }
        }
    }
    .add-vessel {
        color: $main-pink;
        font-size: $small-size;
        cursor: pointer;
    }
    &--note {
        padding-bottom: 50px;
        width: 80%;
        margin: 0 auto;
        p {
            color: $label-gray;
            font-size: 13px;
        }
    }
    .tr__collapse {
        &--content {
            p:not(.error) {
                border-bottom: 1px solid $bottom-border-gray;
                line-height: normal;
                min-height: 37px;
            }

            .tr__verify-details-form--group--content {
                border-bottom: 1px dashed $bottom-border-gray;
                padding-bottom: 30px;
            }
        }
        &.details {
            .tr__collapse--content {
                padding: 0;
            }
        }
    }
}