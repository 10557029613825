@import 'styles/extends/_generics';

$wide-container-width: 1000px;

.tr__fta-advisor {
    @extend %card-box;
    padding: 0;

    &--header {
        padding: $modal-padding;
        border-bottom: 1px solid $bottom-border-gray;
    }

    &--content {
        width: $wide-container-width;
        margin: 30px auto;

        &.wide {
            width: $wide-container-width;
        }
    }

    &--footer {
        @extend %section-footer;
    }
}