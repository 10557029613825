@import 'styles/extends/_generics';

.tr__shipment-info {
    @extend %card-box;
    padding: 0;

    .tr__details-box {
        &:nth-last-child(2) {
            &:after {
                left: -$modal-padding;
                width: calc(100% + #{$modal-padding * 2});
            }
        }
    }

    &--content {
        padding: $modal-padding;
    }

    &--footer {
        @extend %section-footer;
    }
}