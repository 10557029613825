@import 'styles/extends/_generics';

$container-width: 1500px;
$search-results-width: 520px;
$search-results-width-tablet: 380px;
$map-height: 600px;

.tr__shipment-map {
    background-color: transparent;
    border-radius: 5px;
    @extend %enable-transition;
    .tr__search-result .form-group {
        padding: 20px 10px;
        max-height: 100%;
        overflow: hidden;
        overflow-y: visible;
    }
    .tr__collapse--title {
        justify-content: center !important;
        padding: 0;
        @extend %enable-transition;

        .toggle-btn {
            margin-left: 10px;
        }
    }

    &.active {
        background-color: $breadcrum-gray;

        .tr__collapse--title {
            padding: 20px 15px;
        }
    }

    &--container {
        width: 100%;
        margin: 0 auto;
        padding-bottom: 20px;

        @include media-breakpoint-up(xxl) {
            width: $container-width;
        }
    }

    &--map {
        flex-grow: 1;
        margin-right: 15px;
        @extend %box;
        padding: 0;
        min-height: $map-height;

        @include media-breakpoint-up(xxl) {
            margin-right: 30px;
        }

        .tr__map {
            height: 100%;
            &--box {
                border-radius: 5px;
                &:before {
                    display: none;
                }
            }
        }
    }

    &--info {
        width: $search-results-width-tablet;
        @extend %box;
        padding: 0;

        @include media-breakpoint-up(xxl) {
            width: $search-results-width;
        }

        .tr__search-result {
            &--process {
                margin: 0;

                .step {
                    &:last-child {
                        .step--info {
                            &__times {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
            
            .form-group {
                > p {
                    margin-top: 0;
                }
            }
        }
    }
}