@import 'styles/extends/_generics';

$icon-size: 35px;
$border-btn-size: 22px;
$settings-btn-size: 40px;
$statuses-width: 170px;

.tr__template-task-item__wrapper {
    background-color: $white;
    cursor: move;
    .tr__template-task-item {
        border: 1px solid $bottom-border-gray;

        &.active {
            .tr__collapse--content {
                max-height: 100% !important;
            }
        }

        .tr__collapse {
            &--title {
                background-color: $button-hover-gray;
        
                > span {
                    font-size: $h5;
                }

                .tr__button {
                    background-color: $border-gray;
                    padding: 10px;
                    color: $label-gray;
                }
            }
        
            &--content {
                background-color: $white;
                // max-height: 100%;
            }
        }

        &--table {
            position: relative;

            .table--header {
                padding: 10px 0 20px 0;
                @extend %task-table-row;
                @extend %border-double-line;
                color: $main-blue;
                margin-bottom: 1px;
            }

            .table--body {
                .column {
                    width: 200px;
                    flex: inherit;
                    
                    .tr__datepicker {
                        position: relative;
                        flex-grow: 1;

                        .tr__field-input {
                            border: none;
                            border-radius: 0;
                            padding: 15px;
                            cursor: pointer;
                        }

                        .tr__badge {
                            @include centered-position(false, true);
                        }
                    }

                    &.partner {
                        padding: 10px 15px;

                        .partners {
                            font-size: $h1;
                            width: 100%;
                            @include flex(row, center, space-between);
                            i {
                                &:last-child {
                                    margin-right: 0;
                                }
                            }

                            span {
                                font-size: $base-size;
                            }
                        }
                    }

                    &.assignee {
                        padding: 10px 15px;

                        .tr__collaborators {
                            width: 100%;
                            justify-content: space-between;
                        }
                    }

                    &.name {
                        position: relative;
                        flex-grow: 1;

                        &:before {
                            content: '';
                            position: absolute;
                            bottom: -1px;
                            left: 0;
                            width: $icon-size;
                            height: 2px;
                            background-color: $white;
                        }

                        .h4 {
                            @include flex(row, center, center);

                            i {
                                margin-right: 10px;
                                width: $icon-size - 10px;
                                height: $icon-size - 10px;
                                border: 2px solid $bottom-border-gray;
                                background-color: $bottom-border-gray;
                                color: $white;
                                border-radius: $icon-size - 10px;
                                @include flex(row, center, center);
                                font-size: $base-size;
                            }
                        }
                    }

                    &.date {
                        width: 150px;
                        > .tr__link {
                            font-size: $base-size;
                        }
                    }
                }
            }

            .table--footer {
                padding: 15px 0;

                .tr__link {
                    font-size: $h4;
            
                    i {
                        width: $icon-size;
                        margin: 0;
                        text-align: center;
                    }
            
                    span {
                        border-bottom: 1px dashed $main-pink;
                        line-height: 1.25;
                    }
                }
            }
        }

        &-btns {
            .tr__button {
                padding-top: 11px;
                padding-bottom: 11px;
            }
        }
    }

    .tr__badge {
        padding: 6px 10px;
        border-radius: 3px;
        right: 15px;
        background-color: $grey-button-date;
        font-size: $tiny-size;
        color: $white;
    }
}