@import 'styles/extends/_generics';

$edge-radius: 5px;

.comments {
    > p {
        padding-left: $modal-padding;
    }

    .comments-item {
        border-bottom: 1px solid $bottom-border-gray;
        padding: $modal-padding;
    
        &.form {
            .comments-item--field {
                width: 100%;     
                background-color: $light-gray;
                padding: $modal-padding;
                border-radius: $edge-radius;
            }
        }
    
        &--avatar {
            img {
                width: $issue-avatar;
                height: $issue-avatar;
                position: relative;
                z-index: 1;
                background-color: $white;
                border-radius: $issue-avatar;
                border: 2px solid $white;
            }
        }
    
        &--content {
            flex-grow: 1;
            word-break: break-all;
            > div {
                flex-grow: 1;
            }
    
            pre {
                white-space: pre-wrap;
            }
    
            .user {
                .h6 {
                    color: $black;
                }
    
                p {
                    font-size: $tiny-size;
                    color: $placeholder-gray;
                }
            }
    
            .content {
                line-height: 1.5;
                p {
                    margin-bottom: 10px;
                }
            }
    
            .gallery {
                @include flex(row, center);
    
                img {
                    max-height: 100px;
                    max-width: 100px;
                }
            }
    
            textarea {
                background-color: inherit;
                resize: none;
            }
    
            i {
                font-size: $medium-size;
                color: $label-gray;
                top: 70% !important;
            }
        }
    
        .tr__button {
            padding: 10px 25px;
        }
    }
}
