@import 'styles/extends/_generics';

.tr__statuses {
    > p {
        margin-right: 10px;
    }

    &--list {
        flex-grow: 1;
        .tr__status {
            height: 5px;
            margin: 0 1px;
            font-size: $extra-tiny-size;
            position: relative;

            &.label {
                &:before {
                    content: attr(percentage) attr(suffix); 
                    position: absolute;
                    right: 0;
                    bottom: 100%;
                }
            }

            @extend %shipment-status-color
        }
    }

    &.thick {
        .tr__status {
            height: 30px;
            border-radius: 3px;
        }
    }
}
