@import 'styles/extends/_generics';

.tr__organization-members {
    @extend %tab-content-box;

    .members {
        padding: $modal-padding;
        padding-top: 0;
        padding-bottom: $modal-padding * 4;
        background-color: transparent;
    }

    .tr__members-footer {
        @extend %border-double-line-top;
    }
}