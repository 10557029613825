@import 'styles/extends/_generics';

.tr__issue-modal {
    .tr__dropdown {
        .tr__field-input {
            @include placeholder() {
                font-weight: normal;
            }
        }
    }
    .tr__multiselect > label {
        margin: 0;
        .h2 {
            font-size: 12px;
            padding-bottom: 10px;
        }
    }
}