@import 'styles/extends/_generics';

$container-width: 800px;

.tr__edit-shipment-info {
    @extend %card-box;
    padding: 0;

    &--title {
        @extend %border-double-line;
        padding: $modal-padding;
    }

    &--content {
        width: $container-width;
        margin: 30px auto;
        .add-vessel, .add-container {
            color: $main-pink;
            font-size: $small-size;
            cursor: pointer;
        }
        .tr__collapse {
            &--content {
                p:not(.error) {
                    border-bottom: 1px solid $bottom-border-gray;
                    line-height: normal;
                    min-height: 37px;
                }
    
                .tr__verify-details-form--group--content {
                    border-bottom: 1px dashed $bottom-border-gray;
                    padding-bottom: 30px;
                }
            }
            &.details {
                .tr__collapse--content {
                    padding: 0;
                }
            }
        }
    }

    &--footer {
        @extend %section-footer;
    }
}
.tr__info-cell--content {
    @extend %ellipse-text;
}

