@import 'styles/extends/_generics';

.tr__assign-modal {
    &--form,
    &--list {
        padding: $modal-padding;
    }

    &--form {
        border-bottom: 1px solid $border-gray;
    }

    &--list {
        .list {
            img {
                width: 35px;
                height: 35px;
                border-radius: 35px;
                overflow: hidden;
            }
        }

        .tr__member--roles {
            .role-icons {
                width: 100%;
                justify-content: flex-end;
                > i{
                    color: $base-text-color;
                }
                
            }
        }
        .tr__assignee-list {
            .tr__member  {
                > i {
                    &.icon-users {
                            width: 35px;
                            height: 35px;
                            border-radius: 35px;
                            @include flex(row, center, center);
                            background-color: $bottom-border-gray;
                            margin-right: 15px;
                        }
                    }

                img {
                    width: 35px;
                    height: 35px;
                    overflow: hidden;
                    border-radius: 35px;
                    margin-right: 15px;
                }
            }
        }
    }
}