@import 'styles/extends/_generics';

$gap: 5px;

.tr__active-shipment-step {
    position: relative;

    &:before {
        content: '';
        width: calc(100% - #{$step-icon-size} - #{$gap * 2});
        height: 2px;
        background-color: $bottom-border-gray;
        border-radius: 2px;
        position: absolute;
        top: $step-icon-size / 2 - 1px;
        left: $step-icon-size + $gap;
    }

    i {
        @extend %step-icon;
    }

    .h5 {
        display: block;
        overflow: hidden;
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .sub {
        font-size: $tiny-size;

        span {
            &:first-child {
                color: $label-gray;
            }
        }
    }

    &.active {
        i {
            color: $white;
            background-color: $main-blue;
        }

        &:before {
            background-color: $active-progress;
        }
    }

    &.icon-only {
        width: $step-icon-size;
        flex-grow: initial !important;
        text-align: center;
        &:before {
            display: none;
        }
    }

    &.right {
        text-align: right;

        &:before {
            left: auto;
            right: $step-icon-size + $gap;
        }

        i {
            margin: 0 0 0 auto;
        }
    }

    &.small {
        padding-right: 15px;
        &:before {
            background-color: transparent;
            border-top: 1px dashed $placeholder-gray;
            top: 10px;
            left: $step-icon-size/1.2 + $gap;
        }

        i {
            background-color: transparent;
            height: auto;
            display: inline-block;
            margin: 0;
            margin-left: -5px;
            color: $base-text-color;
        }

        h6 {
            font-size: $tiny-size;
        }
    }
}