@import 'styles/extends/_generics';

.tr__info-block {
    &--content {
        border: 1px solid $border-gray;
        border-radius: 5px;

        > .row {
            > .col-12 {
                &:last-child {
                    .tr__info-row {
                        border-bottom: 0;
                    }
                }
            }
        }
    }
}