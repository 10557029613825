@import 'styles/extends/_generics';
.tr__request-list {
    .tr__field--wrapper {
        .tr__field-input {
            border-bottom: 1px solid $border-gray;
            padding: 10px 0;
            width: 300px;
            background-color: transparent;
        }
        
        i {
            font-size: $large-size;
            color: $pink ;
            right: 5px;
        }
    }

    .tr__table {
        &--row {
            position: inherit;
        }
    }

    .tr__organization-members-avatar {
        > p {
            @extend %ellipse-text;
            max-width: 130px;
        }
    }
}