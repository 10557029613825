@import 'styles/extends/_generics';
@import 'styles/helpers/_variables';

$edge-radius: 5px;

$border-radius: 5px;
.tr_reference {
    width: 100%;

    &--header {
       background-color: $button-hover-gray;
       padding: 20px;
       border-radius: $border-radius;
    }

    &--form {
        width: $content-width;
        margin: 0 auto;
        margin-top: 40px;
        
        h2 {
            @extend %border-double-line;
            padding-bottom: $modal-padding;
        }

        h3 {
            margin-top: 20px;
            padding-bottom: $modal-padding;

            span {
                color: $main-blue;
            }
        }

        &__details {
            margin-bottom: 60px;

            .reference--info {
                @include flex(row, center);
                padding: 15px 0;
                border-bottom: 1px dashed $border-gray;
                margin: 0;
                user-select: none;

                > label {
                    min-width: 45%;
                    font-size: $base-size;
                    @extend %f-medium;
                    color: $label-gray;
                }

                span {
                    @extend %ellipse-text;
                    word-break: break-all;
                }
            }

            .full-size {
                label {
                    min-width: 22%;
                }

                span {
                    overflow: inherit;
                    white-space: normal;
                }
            }

            .half-size {
                label {
                    min-width: 50%;
                }
            }
        }

        .booking-number {
            font-size: $h6;
            border-bottom: 1px dashed $bottom-border-gray;
            padding: 30px 0 16px 0;
            p {
                color: $label-gray;
                margin-right: 65px;
            }

            > span {
                max-width: calc(100% - 175px);
                word-break: break-all;
            }
        }

        .reference-tab {
            border: 2px solid $bottom-border-gray;
            @extend %tab-border-bottom;
            border-radius: $edge-radius;
            margin-top: 60px;
        }  
    }
}