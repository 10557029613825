@import 'styles/extends/_generics';

.tr__add-collaborator {
    @extend %card-box;
    padding: 0;

    .tr__multiselect {
        padding-bottom: $modal-padding;
        border-bottom: 1px dashed $bottom-border-gray;

        &--wrapper {
            width: 90%;
            margin: 0 auto;
        }
    }

    &--title {
        @extend %border-double-line;
        padding: $modal-padding;
    }

    &--footer {
        @extend %section-footer;
    }
}