@import 'styles/extends/_generics';

.tr__add-partners-form {
    &--group {
        > p {
            font-size: $small-size;
        }

        .tr__multiselect {
            padding-bottom: $modal-padding;
            border-bottom: 1px dashed $bottom-border-gray;

            &--wrapper {
                width: 90%;
                margin: 0 auto;
                .tr__dropdown__label.multiselect .input-wrapper .tr__button {
                    &.addButton  {
                        background-color: $main-pink ;
                        color: $white ;
                    }
                }
            }
        }
    }
}

.tr__partners {
    .tr__item-card {
        flex: 0 0 calc(50% - 5px);
        border: 0;
        background-color: $light-gray;
        margin: 10px 10px 0 0;
        padding: 10px;
        border-radius: 3px;
        position: relative;

        &:nth-child(2n) {
            margin-right: 0;
        }

        .left-column {
            width: auto;
        }
        
        .info {
            .remove-btn {
                @include centered-position(false, true);
                right: 10px;
                margin: 0;
            }
        }
    }
}