@import 'styles/extends/_generics';

$statues-width: 206px;

.tr__details-header {
    .tr__statuses {
        width: $statues-width;
    }

    p {
        color: $label-gray;
    }
}