@import 'styles/extends/_generics';

.tr__add-role {
    .tr__checkbox-group--wrapper {
        .tr__checkbox-group {
            .tr__form-checkbox {
                margin-right: 15px;
                width: calc(100%/3 - 10px);
                margin-top: 15px;
    
                &:nth-child(3n) {
                    margin-right: 0;
                }
                span {
                    font-size: $small-size;
                    padding: 11px 15px;
                }
            }
        }
    }
}