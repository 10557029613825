@import 'styles/extends/_generics';

.tr__details-form {
    @extend %box;
    padding: 0;
    @extend %tab-content-box;

    .tr__organization-details-form,
    .tr__billing-details-form {
        width: $content-width;
        margin: 0 auto;
    }

    .tr__billing-details-form {
        border: 0;
    }
    
    .tr__field-input {
        color: $black;
    } 

    &.view-only {
        label {
            pointer-events: none;
        }
        
        .organization-name {
            .tr__field {
                user-select: none;
                &-input {
                    border: 0;
                }
            }
        }
        .tr__organization-details-form--contact {
            border: 0;

            .tr__field {
                @extend %view-only-input;
            }

            .label {
                padding: 0;
            }
        }

        .tr__billing-details-form, {
            > .tr__field {
                @extend %view-only-input;
            }
        }

        .tr__field {
            &-input {
                background-color: transparent;
                pointer-events: none;

                @include placeholder() {
                    color: transparent;
                }
            }

            &--wrapper {
                flex-grow: 1;
            }
        }
    }
}