@import 'styles/extends/_generics';

.tr__shipment-documents {
    @extend %card-box;

    &--table {
        flex-grow: 1;
    }

    &--uploader {
        width: 25%;
        margin-left: 30px;
    }

    .tr__table {
        box-shadow: none;
        background-color: transparent;

        &--row {
            align-items: initial !important;
            &__column {
                padding: 15px;
                flex: 0 0 15%;
                border-right: 1px solid $multiselect-gray;

                &:first-child {
                    flex: 0 0 45%;
                    padding-left: 0;
                }
        
                &:last-child {
                    border-right: 0;
                    .tr__button {
                        padding: 10px 15px;
                        margin: 0 auto;
                    }
                }

                &:nth-child(2) {
                    flex: 0 0 25%;
                }

                .tr__collaborators {
                    > span,
                    .tr__organization-members-avatar {
                        flex-grow: 1;
                        order: 2;
                        @include flex(row, center, flex-end);
                    }
                }

                .tr__doc-title {
                    width: 100%;
                    i {
                        color: $main-blue;
                    }
                    .favorite-icon { 
                        margin-left: 0;
                        width: 100%;
                    }
                    .view-document {
                        margin-left: 30px;
                        width: 100%;
                    }
                }
            }
        }
    }

    .tr__collaborators {
        .tr__link {
            width: 100%;
            justify-content: space-between;
            margin: 0;
            span {
                min-width: 70px;
            }
        }
    }

    .tr__doc {
        &-date {
            .date {
                font-size: $tiny-size;
                color: $label-gray;
            }
        }
    }
}
