@import 'styles/extends/_generics';

.tr__add-shipment-details-form {
    &--group {
        .tr__radio-group--wrapper {
            .tr__radio-group {
                flex-wrap: wrap;
                &.horizontal {
                    margin: 20px 0;
                }
                .tr__form-radio {
                    margin: 12px 12px 0 0;
                    padding-bottom: 15px;
                    width: calc(100%/4 - 9px);

                    &:nth-child(4n) {
                        margin-right: 0;
                    }
                }
            }
        }

        .tr__checkbox-group {
            .tr__form-checkbox {
                min-width: 30%;
            }
        }

        .template-chooser {
            &--header {
                > small {
                    font-size: $tiny-size;
                    margin-left: 5px;
                    color: $error-red;
                }
            }

            &--content {
                .tr__dropdown {
                    flex-grow: 1;
                }
            }
        }

        .tr__datepicker {
            &.disabled {
                pointer-events: none;
                @include opacity(0.5);
            }
        }
    }
    
}
