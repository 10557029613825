@import 'styles/extends/_generics';

.tr__add-organization {

    .details-heading {
        margin: 0 0 20px 0;
        padding: 0;
        border-bottom: 0;

        &:after {
            display: none;
        }
    }

    .tr__organization-details-form,
    .tr__billing-details-form {
        padding: $modal-padding;
        margin: 0;

        .tr__field {
            &.last {
                margin: 0;
            }
        }
    }

    .tr__organization-details-form {
        border-bottom: 1px dashed $border-gray;
    }

    .tr__organization-details-form__info {
        margin: 0;
    }
}