@import 'styles/extends/_generics';

.tr__active-shipments-header {
    h1 {
        color: $base-text-color;
    }
    
    p {
        color: $label-gray;
        margin-top: 5px;
    }

    h4 {
        background-color: $white;
        padding: 10px 15px;
        font-size: $small-size;
        border: 1px solid $bottom-border-gray;
        border-radius: 3px;
        margin-right: $tabs-size;
        @extend %clickable;

        i {
            margin-right: 10px;
            color: $placeholder-gray;
        }
    }
}